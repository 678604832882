import $ from 'jquery'

function isDevelopment() {
  return location.hostname === 'ntp.onelaunch.site'
}

type AdMarketTiles = {
  id: string
  name: string
  image_url: string
  click_url: string
  impression_url: string
}

type AffinityTiles = {
  rank: string
  brand: string
  iurl: string
  rurl: string
  impurl: string
}

async function fetchTileList(urlApi: string, provider: string) {
  const response =
    provider === 'Affinity'
      ? await $.ajax({
          url: urlApi,
          dataType: 'jsonp',
        })
      : await await $fetch(urlApi)
  let jsonObject
  if (typeof response !== 'object') {
    const json = response.substring(response.indexOf('{'), response.indexOf(')'))
    jsonObject = JSON.parse(json)
  } else {
    jsonObject = response
  }

  if (provider === 'Affinity') {
    return jsonObject?.data?.map((item: AffinityTiles) => {
      return {
        id: item.rank,
        name: item.brand,
        image_url: item.iurl,
        click_url: item.rurl,
        impression_url: item.impurl,
      }
    })
  }
  return jsonObject?.tiles?.map((item: AdMarketTiles) => {
    return {
      ...item,
    }
  })
}

export { isDevelopment, fetchTileList }
